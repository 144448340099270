
.noMarginSpan {
  padding-left:4px;
  padding-top:10px;
  width: 24px;
  background-color: #f0f3f5;
}

.noMarginInput {
  padding-left:4px;
  padding-right: 4px;
  padding-top:6px;
  font-size:12px;
 
}

.learningTypes {
 margin-top: 10px;
 margin-left: 0px;
 border: 0px solid #000000;
 /*margin-bottom: 14px;*/
}

.formSize .input-group-text{
 font-size: 12px;
}
